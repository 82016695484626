import * as React from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import styled from "styled-components"
import { PageProps } from "../shared"
import { green, grey, lilac, navy } from "../shared/colors";
import Responsive from "../components/responsive";
import ReactMarkdown from "react-markdown";
import Layout from "../components/layout"

const Hero = styled.div`
  height: calc(100vh - 60px);
`;

const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2) 40%, rgba(0,0,0,0.8));
  z-index: 1;
`;

const HeroImg = styled.div<{ url: string }>`
  background-image: url(${props => props.url});
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  z-index: 0;
`;

const HeroVid = styled.video`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 0;    
  object-fit: cover;
  min-height: 100%;
  min-width: 100%;
  max-width: 100%;
  height: auto;
`;

const Content = styled.div`
  z-index: 2;
  color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > * {
      margin: 10px;
    }

    &:first-child {
      flex-basis: 50%;
      flex-grow: 0;
    }
  }
`;

const Title = styled.h1`
  font-size: 3em;
  letter-spacing: 3px;
  font-weight: 600;
  text-align: center;
`;

const Subtitle = styled.h2`
  font-size: 1.5em;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  text-align: center;
`;

const BookButton = styled(Link)`
  background: white;
  border-radius: 10px;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 3px;
  padding: 5px 15px;
  font-size: 0.8em;
  text-transform: uppercase;
  color: ${lilac};
`;

const BookButtonFooter = styled(Link)`
  background: ${green};
  border-radius: 10px;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 3px;
  padding: 5px 15px;
  font-size: 0.8em;
  text-transform: uppercase;
  color: white;
  margin-top: 30px;
`;

const BookNowContainer = styled.div`
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${grey} !important;
  padding-bottom: 30px;

  &:before { 
      height: 2px;
      content: '';
      background: ${green};
      display: block;
      width: 100px;
      margin: 0 auto 50px;
  }

  &:after { 
      height: 2px;
      content: '';
      background: ${green};
      display: block;
      width: 100px;
      margin: 50px auto 0;
  }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
`;

const SectionContent = styled.div<{flipped: boolean}>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${props => props.flipped ? 'row-reverse' : 'row'};
  align-self: center;
  justify-content: center;
`;

const SectionImage = styled.div<{ background: string }>`
  background: url(${props => props.background});
  background-position: top;
  background-attachment: fixed;
  background-repeat: no-repeat;  
  background-size: cover;
  color: white;
  flex: 0;
  font-size: 3em;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  padding: 200px 0;
  position: relative;

  > div:last-child {
    z-index: 2;
    display: flex;
    span {
      margin: auto;
      z-index: 5;
      letter-spacing: 3px;
    }
  }

  > div {
    z-index: 1;
    background: rgba(33, 33, 71, 0.3);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

const SectionText = styled.div`
  ${Responsive};
  padding: 10px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  > div {
    font-size: 2em;
    padding: 70px 0 20px;
    flex: 0 0 30%;
    text-transform: uppercase;
    letter-spacing: 3px;
    display: flex;
    align-items: center;
  }
  > p {
    margin: 20px auto 20px;
    text-align: justify;
    font-size: 1.1rem;
    line-height: 1.5;

    img { 
      max-width: 100%;
    }
  }
`;

const SectionThumbnails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 50px;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 55px;
`;

const SectionThumbnail = styled.div`
  border-radius: 20px;
  border: 3px solid ${green};
  height: 200px;
  width: 300px;
  flex-shrink: 0;
  transition: all 0.5s ease-in-out;
  box-shadow: 0 0 5px 0 rgba(33, 33, 71, 0.2);
  margin: 15px;
  position: relative;
  overflow: hidden;
`; 

const SectionThumbnailChild = styled.div<{src: string}>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  transition: all 0.5s ease-in-out;

  &:hover {
    box-shadow: 0 0 8px 0 rgba(33, 33, 71, 0.5);
    transform: scale(1.1);
  }
`; 

const SeeMore = styled.p`
  @keyframes bounce { 
    0%, 20%, 80%, 100% {transform: translateY(0);}
    50% {transform: translateY(-5px);}
  } 

  color: white;
  margin: 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
  animation-name: bounce;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transition: all ease-in 0.3s;
  opacity: 1;
  justify-content: end;
  cursor: pointer;

  &:after {
    content: '';
    width: 8px;
    height: 8px;
    border: 1px solid white;
    transform: rotate(45deg);
    border-width: 0 1px 1px 0;
    position: absolute;
    margin-left: 5px;
    margin-bottom: 7px;
  }
`;

type HomeProps = {
  subtitle: string,
  title: string,
  heroImg: string,
  heroVid: string,
  bottomBookNowText: string,
  sections: {
    subtitle: string,
    title: string,
    text: string,
    background: string,
    images: {
      image: string
    }[]
  }[]
};

const getVideoMimeType = (url: string): string => {

  if (url) {
    const extension = url.split('.').reverse()[0];
    return `video/${extension}`;
  } else {
    return '';
  }
}

const InitScrollListener = (window: any) => {
  window.onscroll = function () {
    const seeMore = document.getElementById('seeMore');
    const scrolling = window.pageYOffset > 0;
    if (!!seeMore) {
      if (scrolling) {
        seeMore.style.opacity = '0';
      } else {
        seeMore.style.opacity = '1';
      }
    }
  }
}

const scroll = () => {
  if (typeof window !== `undefined`) {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    })
  }
}

const IndexPage = (data: PageProps<HomeProps>) => {
  const { heroImg, heroVid, title, subtitle, sections, bottomBookNowText } = data.data.pageData;
  if (typeof window !== `undefined`) {
    InitScrollListener(window);
  }
  return (
    <Layout transparent={true}>
      <Seo title="Home" />
      <Hero>
        <HeroOverlay />
        <HeroVid hidden={!heroVid} muted autoPlay loop poster={heroImg}>
          <source src={heroVid} type={getVideoMimeType(heroVid)}></source>
        </HeroVid>
        <HeroImg url={heroImg} hidden={!!heroVid} />
        <Content>
          <div></div>
          <div>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
            <BookButton to="https://edubconversions.co.uk/edub-trips-booking">
              Book Now
            </BookButton>
          </div>
          <SeeMore onClick={() => scroll()} id="seeMore">See more</SeeMore>
        </Content>
      </Hero>
      {sections.map((s, i) => { 
        return <Section key={`section-${i}`}>
        <SectionImage background={s.background}>
          <div></div>
          <div><span>{s.title}</span></div>
        </SectionImage>
        <SectionContent flipped={i % 2 === 0}>
          <SectionText>
            <div>{s.subtitle}</div>
            <p>
              <ReactMarkdown>{s.text}</ReactMarkdown>
              </p>
          </SectionText>
          <SectionThumbnails>
            {s.images.map((i, imgIndex) => {
              return <SectionThumbnail key={`thumbnail-${i}-${imgIndex}`}>
                <SectionThumbnailChild src={i.image}></SectionThumbnailChild>
              </SectionThumbnail>
            })}
          </SectionThumbnails>
        </SectionContent>
      </Section>})}

      <Section>
        <BookNowContainer>
          {bottomBookNowText}
          <BookButtonFooter to="https://edubconversions.co.uk/edub-trips-booking">book now</BookButtonFooter>
        </BookNowContainer>
      </Section>
    </Layout>
  )
};

export const query = graphql`
  {
    pageData: homeJson {
      title
      subtitle
      heroVid
      heroImg
      bottomBookNowText
      sections {
        background
        subtitle
        text
        title 
        images {
          image
        }
      }
    }
  }
`;

export default IndexPage;
