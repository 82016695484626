import React, { Component } from "react";
import { graphql, Link, Script } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import { PageProps } from "../shared";
import Responsive from "../components/responsive";
import Calendar from "../components/booking/calendar";
import CamperSelection from "../components/booking/camperSelection";
import { green } from "../shared/colors";
import Details from "../components/booking/details";
import BookingSectionHeader from "../components/booking/bookingSectionHeader";
import * as moment from 'moment';
import Summary from "../components/booking/summary";
import { makeSmoobuReservation } from "../services/smoobu.service";
import { toast } from "react-toastify";

declare var BookingToolIframe: { initialize: any };

const Container = styled.div`
${Responsive};
  display: flex;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  align-items: stretch;
`;

const Header = styled.h1`
  ${Responsive};
  margin: 20px 0 ;
`;

const GoHomeButton = styled(Link)`
  background: ${green};
  border-radius: 10px;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 3px;
  padding: 5px 15px;
  font-size: 0.8em;
  text-transform: uppercase;
  color: white;
  margin: auto;
`;


type BookingProps = { headerImg: string, title: string, campers: Camper[] };

export type Camper = { name: string, colour: string, smoobuId: number, info: string, image: string };
export type CamperAvailability = Camper & { available: boolean, price: number };

type BookingState = {
  from: moment.Moment | null,
  to: moment.Moment | null,
  campers: CamperAvailability[],
  camper: CamperAvailability | null,
  detailsDone: boolean,
  datesDone: boolean,
  camperDone: boolean,
  detailsActive: boolean,
  datesActive: boolean,
  camperActive: boolean,
  confirmActive: boolean,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  finishedSubmitting: boolean,
  showConfirm: boolean
  smoobuLoaded: boolean
}


export const query = graphql`
{
  pageData: bookingJson {
    headerImg
    title
    campers {
      name
      smoobuId
      colour
      info
      image
    }
  }
}
`;

export const SubmitButton = styled.button`
    background: ${green};
    color: white;
    border-radius: 5px;
    border: 0;
    padding: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 20px 0;
    cursor: pointer;
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

export class Booking extends Component<PageProps<BookingProps>, BookingState> {
  constructor(props: PageProps<BookingProps>) {
    super(props);
    this.state = {
      from: null,
      to: null,
      campers: [],
      camper: null,
      camperDone: false,
      datesDone: false,
      detailsDone: false,
      camperActive: false,
      datesActive: true,
      detailsActive: false,
      confirmActive: false,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      finishedSubmitting: false,
      showConfirm: false,
      smoobuLoaded: false
    };
    this.onCalendarComplete = this.onCalendarComplete.bind(this);
    this.datesHeaderClicked = this.datesHeaderClicked.bind(this);
    this.camperSelectionHeaderClicked = this.camperSelectionHeaderClicked.bind(this);
    this.detailsHeaderClicked = this.detailsHeaderClicked.bind(this);
    this.onCamperSelectionComplete = this.onCamperSelectionComplete.bind(this);
    this.onDetailsSectionComplete = this.onDetailsSectionComplete.bind(this);
    this.submit = this.submit.bind(this);
  }

  onCalendarComplete(from: moment.Moment, to: moment.Moment, availableCampers: CamperAvailability[]) {
    this.setState({
      from: from,
      to: to,
      campers: availableCampers,
      datesActive: false,
      datesDone: true
    }, () => {
      this.setState({
        camperActive: true
      });
    });
  }

  onCamperSelectionComplete(camper: CamperAvailability) {
    this.setState({
      camper: camper,
      camperDone: true,
      camperActive: false
    });
    setTimeout(() => {
      this.setState({
        detailsActive: true
      });
    }, 500);
  }

  onDetailsSectionComplete({ firstName, lastName, email, phone }: { firstName: string, lastName: string, email: string, phone: string }) {
    this.setState({
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      detailsActive: false,
      detailsDone: true
    });
    setTimeout(() => {
      this.setState({
        confirmActive: true
      });
    }, 500);
  }

  datesHeaderClicked() {
    if (this.state.datesDone) {
      this.setState({
        camperActive: false,
        detailsActive: false,
        confirmActive: false
      }, () => {
        this.setState({
          datesActive: true,
          datesDone: false,
          camperDone: false,
          detailsDone: false,
        });
      });
    }
  }

  camperSelectionHeaderClicked() {
    if (this.state.camperDone) {
      this.setState({
        detailsActive: false,
        confirmActive: false
      }, () => {
        this.setState({
          camperActive: true,
          camperDone: false,
          detailsDone: false,
        });
      });
    }
  }

  detailsHeaderClicked() {
    if (this.state.detailsDone) {
      this.setState({
        confirmActive: false
      }, () => {
        this.setState({
          detailsActive: true,
          detailsDone: false,
        });
      });
    }

  }

  submit() {
    this.setState({ finishedSubmitting: false });
    const { camper, firstName, lastName, from, to, email, phone } = this.state;
    makeSmoobuReservation({
      camperSmoobuId: camper!.smoobuId,
      firstName: firstName,
      from: from!,
      lastName: lastName,
      to: to!,
      email: email,
      price: camper!.price,
      phone: phone
    })
      .then((res) => {
        if (res.error) {
          console.log(res.error);
          toast.error('An error occured confirming the Booking. Please try again, or email hello@edubtrips.co.uk.');
        } else {
          toast.success('Booking made successfully');
          this.setState({ showConfirm: true })
        }
      })
      .finally(() => {
        this.setState({ finishedSubmitting: true });
      });
  }

  bookingForm(state: BookingState, campers: Camper[]) {
    return <>
      <BookingSectionHeader name="Select Dates" done={state.datesDone} active={state.datesActive} onClick={this.datesHeaderClicked}></BookingSectionHeader>
      <Calendar complete={this.onCalendarComplete} campers={campers} />
      <BookingSectionHeader name="Select Your eDub" done={state.camperDone} active={state.camperActive} onClick={this.camperSelectionHeaderClicked}></BookingSectionHeader>
      <CamperSelection complete={this.onCamperSelectionComplete} campers={state.campers} />
      <BookingSectionHeader name="Your Details" done={state.detailsDone} active={state.detailsActive} onClick={this.detailsHeaderClicked}></BookingSectionHeader>
      <Details complete={(ev) => this.onDetailsSectionComplete(ev)} />
      <BookingSectionHeader name="Confirm" done={false} active={state.confirmActive} onClick={() => { }}></BookingSectionHeader>
      <Summary complete={this.submit} {...this.state}></Summary>
    </>
  }

  confirmation(state: BookingState) {
    return <>
      <Header>Thank you, {state.firstName}!</Header>
      <p>A confirmation email has been sent to {state.email}, where you will find further details about your booking.</p>
      <GoHomeButton to="/">Return Home</GoHomeButton>
    </>
  }

  render() {
    const { headerImg, title, campers } = this.props.data.pageData;
    const showConfirm = this.state.showConfirm;
    const smoobuLoaded = this.state.smoobuLoaded;
    if (smoobuLoaded) {
      BookingToolIframe.initialize({"url": "https://login.smoobu.com/en/booking-tool/iframe/345172", "baseUrl": "https://login.smoobu.com", "target": "#apartmentIframeAll"})
    }
    return (
      <Layout header={headerImg} title={title} transparent={true}>
        <Seo title="Booking" />
        <Container>
          <div id="apartmentIframeAll">
            <Script onLoad={() => {this.setState({smoobuLoaded: true})}} src="https://login.smoobu.com/js/Settings/BookingToolIframe.js"></Script>
          </div>
          {/* {showConfirm ? this.confirmation(this.state) : this.bookingForm(this.state, campers)} */}
        </Container>
      </Layout>);
  }
}

export default Booking;